<template>
    <div class="complaint">
        <navbar/>
        
        <div class="complaint__content" >
                <transition name="fade">
            <div class="complaint__form" v-if="step === 1">

                    <div class="complaint__header">
                        <h4>Zgłoś reklamację lub zwrot</h4>
                        <span> Uzupełnij pola formularza, aby kontynuować. </span>
                    </div>

                    <label class="complaint__label">Typ zgłoszenia:</label>
                        <select class="complaint__input" @change="onChangeType" v-model="complaint.type">
                            <option value="Zwrot">Zwrot</option>
                            <option value="Reklamacja">Reklamacja</option>
                        </select>


                    <label class="complaint__label">Powód reklamacji/zwrotu produktu:</label>
                    <input class="complaint__input" type="text" placeholder="np. Produkt uszkodzony w transporcie" v-model="complaint.reason">
                    <div v-if="complaint_errors.reasonError" class="complaint__error"> Podaj powód reklamacji/zwrotu. </div> 

                    <div class="complaint__button">
                        <div class="complaint__button__bttn">
                            <bttn @clicked="errorCheck()" :cta="'Dalej'"></bttn>
                        </div>
                    </div>

            </div>
                </transition>
    <transition name="fade">
            <div class="complaint__form" v-if="step === 2">
                    <div class="complaint__header">
                        <h4>Zgłoś reklamację lub zwrot</h4>
                        <span> Uzupełnij pola formularza, aby kontynuować. </span>
                    </div>

                    <label class="complaint__label">Dane nabywcy:</label>
                    <input class="complaint__input" type="text" placeholder="np. Tomasz Kowalski"  v-model="complaint.clientName">
                    <div v-if="complaint_errors.clientNameError" class="complaint__error"> Musisz podać swoje dane.</div>

                    <label class="complaint__label">Ulica:</label>
                    <input class="complaint__input" type="text" placeholder="np. ul. Wesoła 37" v-model="complaint.street">
                    <div v-if="complaint_errors.streetError" class="complaint__error"> Musisz podać swoją ulicę.</div> 

                    <label class="complaint__label">Kod pocztowy:</label>
                    <input class="complaint__input" type="text" placeholder="np. 63-500" v-model="complaint.zipCode">
                    <div v-if="complaint_errors.zipCodeError" class="complaint__error"> Musisz podać swój kod pocztowy.</div> 

                    <label class="complaint__label">Miejscowość:</label>
                    <input class="complaint__input" type="text" placeholder="np. Rogaszyce" v-model="complaint.town">
                    <div v-if="complaint_errors.townError" class="complaint__error"> Musisz podać swoją miejscowość.</div> 

                    <label class="complaint__label">Email:</label>
                    <input class="complaint__input" type="email" placeholder="przykład@gmail.com"  v-model="complaint.email">
                    <div v-if="complaint_errors.emailError" class="complaint__error"> Błędny adres e-mail.</div>

                    <label class="complaint__label">Numer telefonu:</label>
                    <input class="complaint__input" type="tel" id="phone" name="phone" placeholder="np. 724 463 729" pattern="[0-9]{9}"  v-model="complaint.tel">
                    <div v-if="complaint_errors.telError" class="complaint__error" > Musisz podać numer telefonu.</div>

                    <div class="complaint__button" v-if="!loading">
                        <div class="complaint__button__bttnBackward">
                            <bttn @clicked="changeStep(1)" :cta="'Cofnij'"></bttn>
                        </div>
                        <div class="complaint__button__bttnForward">
                            <bttn @clicked="errorCheck()" :cta="'Dalej'"></bttn>
                        </div>
                    </div>
            </div>
    </transition>
    
    <transition name="fade">
            <div class="complaint__form" v-if="step === 3">

                    <div class="complaint__header">
                        <h4>Zgłoś reklamację lub zwrot</h4>
                        <span> Uzupełnij pola formularza, aby kontynuować. </span>
                    </div>

                    <label class="complaint__label">Reklamowany produkt:</label>
                    <input class="complaint__input" type="text" placeholder="np. Laptop Lenovo Ideapad"  v-model="complaint.productName">
                    <div v-if="complaint_errors.productNameError" class="complaint__error"> Musisz podać nazwę produktu.</div>

                    <label class="complaint__label">Sposób dostarczenia produktu do serwisu:</label>
                        <select class="complaint__input" v-model="complaint.deliveryType">
                            <option value="We wlasnym zakresie">Wysyłam we własnym zakresie</option>
                            <option value="Wyslij kuriera">Proszę o zamówienie kuriera po odbiór sprzętu</option>
                        </select>
                    
                    <label class="complaint__label">Data zakupu:</label>
                    <input class="complaint__input" type="date"  v-model="complaint.purchaseDate">
                    <div v-if="complaint_errors.purchaseDateError" class="complaint__error"> Musisz wybrać datę zakupu. </div>

                    <label class="complaint__label">Numer zamówienia/Numer faktury:</label>
                    <input class="complaint__input" type="text" placeholder="np. 106147" v-model="complaint.orderNum">
                    <div v-if="complaint_errors.orderNumError" class="complaint__error"> Musisz podać numer zamówienia/faktury. </div>

                    <div v-if="this.complaint.moneyReturn === true">
                    <label class="complaint__label">Numer konta bankowego:</label>
                    <input class="complaint__input" type="text" placeholder="np. 87 1010 1397 0055 0022 2100 0000"  v-model="complaint.bankAccount">
                    <div v-if="complaint_errors.bankAccountError" class="complaint__error"> Musisz podać numer konta.</div> 
                    </div>
            
                    <div>
                    <label class="complaint__label">Zdjęcia uszkodzeń (dodanie zdjęć przyspieszy proces reklamacyjny):</label>
                    <div class="complaint__input">
                    <input type="file"
                        ref="fileUploader"
                        accept="image/*" multiple  placeholder="123-45-678">
                    </div>
                    <div class="complaint__required">Wymagany format jpg/png.</div>
                    <div v-if="complaint_errors.inputError" class="complaint__error">Musisz dodać poprawne zdjęcie.</div>
                    </div>

                    <div class="complaint__button">
                        <div class="complaint__button__bttnBackward">
                            <!-- <bttn @clicked="changeStep(2)" :cta="'Cofnij'"></bttn> -->
                            <div class="mainButton">
                                <button :disabled="loading" @click="changeStep(2)">Cofnij</button>
                            </div>
                        </div>
                        <div class="complaint__button__bttnForward">
                            <!-- <bttn @clicked="errorCheck()" :cta="'Wyślij'"></bttn> -->
                            <div class="mainButton">
                                <button :disabled="loading" @click="errorCheck()">Wyślij</button>
                            </div>
                        </div>
                    </div>
            </div>   
            </transition>     
            
    <transition name="fade">        
            <div class="complaint__form" v-if="step === 4">

                    <div class="complaint__header">
                        <h4>Gratulacje, udało Ci się zgłosić reklamację/zwrot.</h4>
                    </div>

                    <div class="complaint__button">
                        <div class="complaint__button__bttnSend">
                            <bttn @clicked="mainSite()" :cta="'Wróć do strony głównej'"></bttn>
                        </div>
                    </div>
            </div>   
    </transition>     


        </div>
        <trust/>
        <ftr/>
    </div>

</template>
<script>
    import {
    db,storage
    } from '../configs/firebase';
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";
    import bttn from "../components/mainButton.vue";
    import axios from 'axios';

export default {
    data() {
      return {
          loading: false,
          step: 1,
          complaint: {
                clientName: '',
                email: '',
                tel: '',
                type: 'Reklamacja',
                expect: 'Naprawy urządzenia lub wymiany',
                productName: '',
                purchaseDate: '',
                orderNum: '',
                adress: '',
                street: '',
                zipCode: '',
                town: '',
                adress: '',
                deliveryType: 'We wlasnym zakresie',
                moneyReturn: false,
                bankAccount: '',
                productReturn: false,
                images: [],
                reason: ''
          },
        complaint_errors: {
                clientNameError: false,
                emailError: false,
                telError: false,
                productNameError: false,
                purchaseDateError: false,
                orderNumError: false,
                adressError: false,
                streetError: false,
                zipCodeError: false,
                townError: false,
                bankAccountError: false,
                reasonError: false,
                inputError: false,
        }
      }
    },
    mounted()
    {
        window.scrollTo(0,0);
    },
    // updated(){
    //     if(this.complaint.type === 'Zwrot'){
    //        this.complaint.expect = 'Pieniądze' 
    //     }
    // },
    methods: 
    {
        onChangeType(){
        if(this.complaint.type === 'Zwrot'){
           this.complaint.expect = 'Pieniądze' 
        }
        if(this.complaint.type === 'Reklamacja'){
           this.complaint.expect = 'Naprawa' 
        }
        },
        money (){
            if(this.complaint.expect === 'Pieniądze'){
                this.complaint.moneyReturn = true
            }
            else{
                this.complaint.moneyReturn = false
                }
        },

        productReturnImages(){
            if(this.complaint.type === 'Zwrot'){
                this.complaint.productReturn = true
            }
            else{
                this.complaint.productReturn = false
                }
        },

        mainSite(){
            this.$router.push("/");
        },
        
        changeStep(n){
            this.step = 0;
            window.scrollTo(0,0);
            setTimeout(()=>{
            this.step = n;
            },500)
        },

        errorCheck(){
            if (this.step === 3){
                if(this.complaint.productName.length > 2 )
                {
                    this.complaint_errors.productNameError = false;

                    if(this.complaint.purchaseDate.length > 2 )
                    {
                        this.complaint_errors.purchaseDateError = false;

                        if(this.complaint.orderNum.length > 2)
                        {
                            this.complaint_errors.orderNumError = false;
                            if(this.complaint.moneyReturn === true){
                                if(this.complaint.bankAccount.length > 2)
                                {
                                    this.complaint_errors.bankAccountError = false;
                                    this.loading = true;
                                    this.send()
                                }
                                else
                                {
                                    this.complaint_errors.bankAccountError = true;
                                    return 0;
                                }
                            }
                            else{
                                {
                                    this.complaint.images = [];
                                    this.send()
                                    }
                            }
                        }
                        else{
                            this.complaint_errors.orderNumError = true;
                            return 0;
                        }
                    }
                    else{
                        this.complaint_errors.purchaseDateError = true;
                        return 0;
                    }
                }
                else{
                    this.complaint_errors.productNameError = true;
                    return 0;
                }
            }


            if (this.step === 2){
                if(this.complaint.clientName.length > 2 )
                {
                    this.complaint_errors.clientNameError = false;

                    if(this.complaint.street.length > 2 )
                    {
                        this.complaint_errors.streetError = false;

                        if(this.complaint.zipCode.length > 2)
                        {
                            this.complaint_errors.zipCodeError = false;

                            if(this.complaint.town.length > 2)
                            {
                                this.complaint_errors.townError = false;

                        if(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.complaint.email))
                        {
                            this.complaint_errors.emailError = false;

                            if(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(this.complaint.tel))
                            {
                                this.complaint_errors.telError = false;
                                this.changeStep(3);
                            }
                            else
                            {
                                this.complaint_errors.telError = true;
                                return 0;
                            }
                        }
                        else
                        {
                            this.complaint_errors.emailError = true;
                            return 0;
                        }
                        }
                        else
                        {
                            this.complaint_errors.townError = true;
                            return 0;
                        }
                        }
                        else
                        {
                            this.complaint_errors.zipCodeError = true;
                            return 0;
                        }
                    }
                    else{
                        this.complaint_errors.streetError = true;
                        return 0;
                    }
                }
                else{
                    this.complaint_errors.clientNameError = true;
                    return 0;
                }
            }


            if (this.step === 1){
                if(this.complaint.reason.length > 2 )
                    {
                        this.complaint_errors.reasonError = false;
                        this.changeStep(2);
                        this.money();
                        this.productReturnImages();
                    }
                    else
                        {
                            this.complaint_errors.reasonError = true
                            return 0;
                        }
            
            };
        },
        
        createFileName(length) {
            var result = [];
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
            result.push(characters.charAt(Math.floor(Math.random() * 
                charactersLength)));
                }
            return result.join('');
        },
        async send()
        {
            try
            {
                let isPdf = false;
                this.loading = true
                for(let i=0;i<this.$refs.fileUploader.files.length;i++)
                {
                    let currentFile = this.$refs.fileUploader.files[i];
                    if(currentFile.type === 'image/jpeg' || currentFile.type === 'image/png')
                    {
                        this.complaint_errors.inputError = false;
                        let link = await this.uploadImage(currentFile)
                        this.complaint.images.push(link)
                    }
                    else
                    {
                        isPdf = true
                        this.loading = false;
                        this.complaint_errors.inputError = true;
                        this.$refs.fileUploader.value = ''
                        this.complaint.images = [];
                    }

                }
                    if(!isPdf)
                    {
                        let attemptToAdd = await db.collection('Complaints').add(this.complaint)
                        if(attemptToAdd)
                        {
                            await axios.post('https://europe-central2-kompreshop.cloudfunctions.net/webApi/complyFormMail', this.complaint)
                            this.loading = true;
                            this.changeStep(4)
                        }
                    }
            }
            catch (error)
            {
                this.loading = false;
                console.log(error.message);           
            }
            
        },
        async uploadImage(imgRef)
        {
            let storageRef = storage.ref();
            let name = this.createFileName(24);
            let fileRef = storageRef.child(`${name}mainImage`);
            await fileRef.put(imgRef);
            let link = await fileRef.getDownloadURL();
            return link;
        }
    },
    components: {navbar,trust,ftr,bttn},
}
</script>
<style lang="scss" scoped>
    
    @import '../styles/variables';
    .mainButton
{
    margin-top: 1.25rem;    
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button
    {
        background: none;
        font-size: 1rem;
        color: $red;
        border: 2px solid $red;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 100%;
        border-radius: 5px;
        transition: all 0.4s ease-in-out;
    }

    button:hover
    {
        cursor: pointer;
        border: 2px solid $redHover;
        color: $redHover;
        transform: translateY(-1px);
        box-shadow: 0 1px 3px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.24);
    }
    button:disabled
    {
        opacity: 0.5;
        cursor: pointer;
        border: 2px solid $redHover;
        color: $redHover;
        transform: translateY(-1px);
        box-shadow: 0 1px 3px rgba(0,0,0,0.24), 0 1px 2px rgba(0,0,0,0.24);
    }
}

    .complaint
    {
        width: 100vw;
        &__header
        {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            margin: 0;
            padding: 0;
                h4
                {
                    text-align: center;
                    color: $black;
                    font-weight: 500;
                    font-size: 1.563rem;
                    margin: 0;
                    padding: 0;
                }
                span{
                    text-align: center;
                    margin: 0;
                    margin-bottom: 1.25em;;
                    padding: 0;
                }
        }
    
        &__content
        {
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;
            margin-top: 11.117em;
        }

        &__form
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 30%;
            margin: 1.875rem auto;
            border: 1px solid $border;
            text-align: left;
            padding: 2rem;
            border-radius: 0.75rem;
            
        }

        &__label
        {
            color: $gray;
            display:block;
            margin: 0.75rem 0 0.75rem;
            font-size: 0.6em;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: bold; 
        }
        &__required
        {
            color: $gray;
            display:block;
            margin-top: 0.25rem;
            font-size: 0.6em;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
        
        &__input
        {
            display: inline-block;
            padding: 10px 6px;
            width:100%;
            box-sizing: border-box;
            border: none;
            border-bottom: 1px solid $connectorColor;
            color: #555;
            border-radius: 0;
        }


        &__button
        {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            margin-top: 1.563REM; 
            &__bttn 
            {
            display: flex;
            flex-direction: row;
            width: 30%;
            }
            &__bttnForward
            {
            display: flex;
            flex-direction: row;
            min-width: 30%;
            margin-left: 1rem;
            }
            &__bttnBackward
            {
            display: flex;
            flex-direction: row;
            min-width: 30%;
            margin-right: 1rem;
            }
            &__bttnSend
            {
            display: flex;
            flex-direction: column;
            width: 100%;
            }
        }

        &__imgButton
        {
            justify-content: right;
            background: #0b6dff;
            border: 0;
            padding: 5px 10px;
            color: white;
            border-radius: 20px;
            cursor: pointer;
        }


        &__error
        {
            color: $red;
            margin-top: 5px;
            margin-left: 5px;
            font-size: 0.59em;
            font-weight: bold;
        }

        &__box{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            margin: 0.328rem;
            &__registration{
                text-align: center;
                margin: 0;
                padding: 0;
                color: #555;
                font-weight: 500;
            }

            &__data{
                text-align: center;
                color: $gray;
                margin: 0;
                padding: 0;
                inline-size: 100%;
                overflow-wrap: break-word;
            }
        }

    }
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


@media only screen and (max-width: 992px) {
    .complaint__form{
        max-width: 100%;
    }
}
@media (max-width: 769px) {
    .complaint__content{
        margin-top: 5.96em;
    }
  }
</style>